/* eslint-disable max-len */
const TrainingContent = [
  {
    id: 1,
    title: 'Public Gouvernance (PG):',
    description:
      "cette catégorie regroupe toute personne ou entité impliquée au niveau de la gouvernance, la facilitation et/ou la prise de décisions en relation avec l’association Horizon Europe et les activités mises en place. Il s’agit de décideurs comme les ministres, les directeurs généraux, les responsables des entités de recherche ou d’interfaçage.Dans le cadre de renforcement de la participation de la Tunisie aux programmes cadres européens pour la recherche et l'innovation, une offre de formation est mise en place. Ces formations sont gratuites et s’adressent à plusieurs natures de participants, et à différents stades de maturité.",
  },
  {
    id: 2,
    title: 'Public Communauté de R&I (PC):',
    description:
      'cette catégorie regroupe tous les bénéficiaires des appels à candidature Horizon Europe comme les chercheurs, les responsables d’entités de recherche, les responsables d’entreprises et la société civile impliquées ou intéressées par les projets de recherche, etc. C’est la catégorie appelée à participer aux appels du programme Horizon Europe.',
  },
  {
    id: 3,
    title: 'Public Réseau (PR):',
    description:
      'Il s’agit d’un public dont la tâche principale est de véhiculer l’information sur les opportunités et appels Horizon Europe, accompagner les porteurs de projets, faciliter le partenariat, conseiller et coacher les candidats. Il s’agit des personnes suivant ; personnel de l’UGPO, PCN, PFI, Project Management Office (PMO), Conseillers Scientifiques (CS), membres des Réseaux Thématiques Nationaux (RTN), Ambassadeurs Scientifiques (AS) ou Cabinets d’Ingénierie de Projet Européens',
  },
  {
    id: 4,
    title: 'important : données personnelles et droits d’image :',
    description: ` à des fins de communication, de dissémination et de pérennisation, les formations, ateliers et événements sont sujets à prises de vues et vidéos publiées sur les réseaux sociaux et dans les bibliothèques numériques. En vous inscrivant à ces formations, vous acceptez d’apparaître dans ces contenus.
De même, en vous inscrivant à ces formations, vous acceptez le traitement de vos données personnelles qui sont intégrées dans le système d’information de l’UGPO à des fins de statistiques de participation, traitement et diffusion de l’information à des fins non commerciales. Vos informations sensibles sont traitées de manière confidentielles. Vous disposez naturellement d’un droit de modification ou d’effacement de ces données.`,
  },
];
const CoachingContent = [
  {
    id: 1,
    title: 'L’accompagnement collectif',
    description:
      " vise à accompagner plusieurs porteurs de projets visant le même appel à projet. Il est également délivré sur décision d’un comité de sélection qui examine chaque demande. Tous les demandeurs (acceptés ou non) sont invités à participer à un ou plusieurs ateliers collectifs (dans la limite des places disponibles). Chaque demandeur accepté est invité à poursuivre les ateliers par un accompagnement individuel d’expert lors de plusieurs échanges afin d’améliorer sa proposition.Dans le cadre de renforcement de la participation de la Tunisie aux programmes cadres européens pour la recherche et l'innovation, le Ministère de l’Enseignement Supérieur et de la Recherche Scientifique – Unité de Gestion chargé des Programmes cadres européens pour la R&I, propose d’accompagner gratuitement les candidats les plus aptes à remporter des appels à projets.Pour cela, deux modalités d’accompagnement sont mises en œuvre : l’accompagnement individuel et l’accompagnement collectif.",
  },
  {
    id: 2,
    title: 'L’accompagnement individuel',
    description:
      'est délivré sur décision d’un comité de sélection qui examine chaque demande. Si la demande est acceptée, le porteur de projet est accompagné par un expert lors de plusieurs échanges afin d’améliorer sa proposition.',
  },
];

export default { CoachingContent, TrainingContent };
