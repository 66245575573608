/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// UI local components
import PageTitle from '../page-title/index';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Banner Section                               */
/* -------------------------------------------------------------------------- */

function BannerSection({ title, subtitle, description, bannerImage }) {
  /* ********************************* CONST ********************************** */
  // eslint-disable-next-line max-len
  const INLINE_BANNER_BG = `url(${bannerImage})`;

  /* ******************************* RENDERING ****************************** */
  return (
    <Container className="banner-section max-w fluid">
      <Container
        className="banner-container max-w"
        style={{ backgroundImage: INLINE_BANNER_BG }}
      >
        <Container className="w-max">
          <PageTitle title={title} subtitle={subtitle} />
          {description && <p className="banner-descrip">{description}</p>}
        </Container>
      </Container>
    </Container>
  );
}

BannerSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  bannerImage: PropTypes.string.isRequired,
};

BannerSection.defaultProps = {
  subtitle: '',
  description: undefined,
};

export default BannerSection;
