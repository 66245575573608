/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React from 'react';
import { Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Row } from 'react-grid-system';

// Assets
import CALENDAR_ICON from '../../../images/Events/calendar.svg';

/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */

function EventToUse({ URL, DATE, TITLE, DESCRIPTION }) {
  /* ------------------------------ HOOKS ----------------------------------- */

  /* **************************** RENDERING ********************************* */
  return (
    <Link to={URL}>
      <Container className="events-container fluid" fluid>
        <Container className="flex flex-wrap items-center mb-1 fluid">
          <Container className="flex items-center mr-1 fluid" fluid>
            <img src={CALENDAR_ICON} alt="calendar icon" />
            <h2 className="down">{DATE}</h2>
          </Container>
        </Container>
        <Row className="mb-1 fluid">
          <h5>{TITLE}</h5>
        </Row>
        <Row className="mb-1 fluid">
          <p>{DESCRIPTION}</p>
        </Row>
      </Container>
    </Link>
  );
}

EventToUse.propTypes = {
  URL: PropTypes.string.isRequired,
  DATE: PropTypes.string.isRequired,
  TITLE: PropTypes.string.isRequired,
  DESCRIPTION: PropTypes.string.isRequired,
};

export default EventToUse;
