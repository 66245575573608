/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';

// UI lib component
import { Container } from 'react-grid-system';

// UI local component
import EventToUse from './EventToUse';

// APIs
import Data from '../../shared/fake-APIs/Training-coaching';
import { API_JALIOS_COACHING } from '../../shared/APIs/index';
// Style
import './index.scss';
/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */

function Coaching() {
  /* ------------------------------ HOOKS ----------------------------------- */
  const [jaliosCoachingEventsData, setJaliosCoachingEventsData] = useState();
  const [content] = useState(Data.CoachingContent);

  /* ---------------------------------- HELPERS ------------------------------ */
  async function fetchJaliousCoachingEvents() {
    const { data } = await axios(API_JALIOS_COACHING, {
      headers: {
        Accept: 'application/json',
      },
    });
    setJaliosCoachingEventsData(data.dataSet);
  }
  useEffect(() => {
    fetchJaliousCoachingEvents();
  }, []);

  /* **************************** RENDERING ********************************* */
  return (
    <Container className="content fluid">
      {/* FETCH STATIC TRAININGS DATA */}
      {content.map((item) => {
        const { id, title, description } = item;
        return (
          <Container key={id} className="section fluid content-container">
            <h5>{title}</h5>
            <p>{description}</p>
          </Container>
        );
      })}

      {/* FETCH JALIOS TRAINING EVENTS  */}
      {jaliosCoachingEventsData?.map((item) => {
        const { title, udate, description } = item;
        const FORMATED_EVENT_DATE = new Date(udate);
        const STYLED_EVENT_DATE = format(FORMATED_EVENT_DATE, 'dd-MM-yyyy');
        return (
          <EventToUse
            URL={`https://ugpo.horizon-europe.tn/jcms/${item?.id}`}
            DATE={STYLED_EVENT_DATE}
            TITLE={title}
            DESCRIPTION={description}
          />
        );
      })}
    </Container>
  );
}

export default Coaching;
