/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';

// UI lib component
import { Container } from 'react-grid-system';

// UI local component
import EventToUse from './EventToUse';

// APIs
import Data from '../../shared/fake-APIs/Training-coaching';
import { API_JALIOS_TRAININGS } from '../../shared/APIs';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */
function Training() {
  /* ------------------------------ HOOKS ----------------------------------- */
  const [jaliosTrainingEventsData, setJaliosTrainingEventsData] = useState();
  const [content] = useState(Data.TrainingContent);

  /* ---------------------------------- HELPERS ------------------------------ */
  async function fetchJaliousTrainingEvents() {
    try {
      const { data } = await axios(API_JALIOS_TRAININGS, {
        headers: {
          Accept: 'application/json',
        },
      });
      setJaliosTrainingEventsData(data.dataSet);
    } catch (error) {
      setJaliosTrainingEventsData([]);
    }
  }
  useEffect(() => {
    fetchJaliousTrainingEvents();
  }, []);
  /* **************************** RENDERING ********************************* */
  return (
    <Container className="container fluid">
      {/* FETCH STATIC TRAININGS DATA */}
      {content.map((item) => {
        const { id, title, description } = item;
        return (
          <Container key={id} className="section fluid content-container">
            <h5>{title}</h5>
            <p>{description}</p>
          </Container>
        );
      })}

      {/* FETCH JALIOS TRAINING EVENTS  */}
      {jaliosTrainingEventsData?.map((item) => {
        const { title, udate } = item;
        const FORMATED_EVENT_DATE = new Date(udate);
        const STYLED_EVENT_DATE = format(FORMATED_EVENT_DATE, 'dd-MM-yyyy');
        return (
          <EventToUse
            URL={`https://ugpo.horizon-europe.tn/jcms/${item.id}`}
            DATE={STYLED_EVENT_DATE}
            TITLE={title}
            // DESCRIPTION={description}
          />
        );
      })}
    </Container>
  );
}

export default Training;
