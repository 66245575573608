/* eslint-disable jsx-a11y/click-events-have-key-events */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';
import Training from '../page-components/Training-coaching/Training';
import Coaching from '../page-components/Training-coaching/Coaching';

// Assets
import BANNER_IMG from '../images/training-and-coaching-page/banner1.jpg';

// Style
import '../page-styles/training-and-coaching.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function TrainingAndCoachingPgae({ location }) {
  /* ---------------------------------- HOOKS ------------------------------- */
  const [contentPage, setContentPage] = useState('Formation');

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Formation et Coaching" />
      <BannerSection title="Formation  et coaching" bannerImage={BANNER_IMG} />
      <Container className="training-and-coaching-page max-w fluid">
        <Container className="menu-section fluid" fluid>
          <Container>
            <ul className="topMenu flex justify-center fluid">
              <Container
                id={contentPage === 'Formation' ? 'activated-menu-button' : ''}
                className="single-link text-center flex justify-center items-center active fluid"
                onClick={() => {
                  setContentPage('Formation');
                }}
              >
                <li>
                  <h5>Formation</h5>
                </li>
              </Container>
              <Container
                id={contentPage === 'coaching' ? 'activated-menu-button' : ''}
                className="single-link text-center flex justify-center items-center active fluid"
                onClick={() => {
                  setContentPage('coaching');
                }}
              >
                <li>
                  <h5>Coaching</h5>
                </li>
              </Container>
            </ul>
          </Container>
        </Container>

        <Container>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/${location.pathname.split('/')[1]}`}
            >
              Formation et coaching
            </Link>
          </Container>
          {contentPage === 'coaching' ? <Coaching /> : <Training />}
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

TrainingAndCoachingPgae.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default TrainingAndCoachingPgae;
