/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// Prototypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Page Tiltles                                 */
/* -------------------------------------------------------------------------- */

function PageTitle({ subtitle, title }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="style-title-section fluid">
      {subtitle && (
        <span className="subtitle">{subtitle || 'default title'}</span>
      )}
      <h1 className="title-section-style">{title || 'default title'}</h1>
    </Container>
  );
}

PageTitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageTitle;
